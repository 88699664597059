<template>

	<main class="container">
		<div class="content">
			<div class="content_head">
				<div class="inner">
					<div class="breadcrumb">
						<ul>
							<li><a href="#" class="ico_home" title="Home"></a></li>
							<li>회원탈퇴</li>
						</ul>
					</div>
					<h2 class="page_title">회원탈퇴</h2>
				</div>
			</div>
			<div class="content_body">
				<div class="inner">
					<div class="quit_complete">
						<h2 class="tit"><b>WAPU</b>를 이용해주셔서 감사합니다.</h2>
						<div class="desc">
							WAPU를 이용해주시고 사랑해주셔서 감사합니다.<br>
							더욱 더 노력하고 발전하는 WAPU가 되겠습니다.
						</div>
					</div>
					<div class="button_area">
						<button type="submit" class="btn_primary" @click="goHome">확인</button>
					</div>
				</div>
			</div>
		</div>
	</main>

</template>

<script>
export default {
	name: "successQuit",
	mounted() {

	},

	methods: {
		goHome() {
			location.href="/"
		}
	}
}
</script>

<style scoped>

</style>